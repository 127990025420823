@import "./variables";
@import "./mediaquery";

/* Login styles */
.login {
  height: 100vh;
  width: 100vw;
  background-color: #c9d6ff;
  background: url("../assets/imgs/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* Container styles */
  .container {
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 768px;
    min-height: 480px;
    background-color: white;

    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;
      margin: 20px 0;
    }

    /* Span styles */
    span {
      font-size: 12px;
    }

    /* Anchor styles */
    a {
      color: #333;
      font-size: 13px;
      text-decoration: none;
      margin: 15px 0 10px;
    }

    h6 {
      font-size: small;
      font-weight: 400;
      color: $accent !important;
      margin-bottom: 8px;
    }

    /* Button styles */
    button {
      background-color: $primary;
      color: white;
      font-size: 12px;
      padding: 10px 45px;
      border: 1px solid transparent;
      border-radius: 8px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-top: 1em;
      cursor: pointer;

      /* Hidden button styles */
      &.hidden {
        background-color: white;
        border-color: white;
      }
    }

    div {
      transition: opacity 0.5s ease-in-out;
    }

    /* Form styles */
    form {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 40px;
      height: 100%;
    }

    .sign-in {
      position: absolute;
      top: 0;
      left: 100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;

      h1 {
        color: $primary;
        margin-bottom: 1em;
      }
    }
    .right-panel {
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;
      background-color: $secondary;
      background: linear-gradient(to right, $primary, $secondary);
      color: white;
      padding: 1em 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px 0 0 50px;

      p {
        margin: 1em 0 3em 0;
      }

      button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        cursor: pointer;
        display: block;
        margin: auto;
      }
    }

    .reset {
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;
      overflow-y: auto;

      h1 {
        color: $primary;
        margin-bottom: 1em;
      }
    }
    .left-panel {
      position: absolute;
      top: 0;
      left: 100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;
      background-color: $primary;
      background: linear-gradient(to right, $primary, $secondary);
      color: white;
      padding: 1em 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 50px 50px 0;

      p {
        margin: 1em 0 3em 0;
      }

      button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        cursor: pointer;
        display: block;
        margin: auto;
      }
    }

    .popup {
      padding: 1em 0;
      h1 {
        color: $primary;
        margin-top: 10px;
        margin-bottom: 10px !important;
        text-align: center;
      }
      .loading {
        position: fixed !important;
        background-color: transparent !important;
      }

      .div21 {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1em;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      h4 {
        font-weight: 520;
        color: $accent;
        font-size: smaller;
      }

      h6 {
        color: orange;
        text-align: center;
        font-size: small;
        font-weight: 400;
      }

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;

        h3 {
          font-size: medium;
          font-weight: 520;
          color: $primary;
        }
        .fa-times {
          color: orange;
          cursor: pointer;
        }
        .fa-times:hover {
          color: orangered;
        }
      }

      .button {
        background-color: $primary;
        border: none;
        border-radius: 5px;
        padding: 10px 2em;
        margin: auto;
        display: block;
        cursor: pointer;
        color: white;
        box-shadow: 1px 1px #60606030;
      }

      .button:hover {
        background-color: $primary;
      }

      .input {
        margin: 10px 0 10px 0;
        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1em !important;
          font-size: small;
        }

        input {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin: 10px 0 10px 0;
        label {
          margin-bottom: 10px;
          font-size: small;
        }

        select {
          padding: 7px;
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}
