$primary: #dd761c;
$primarylight: #feb941;
$primarylighter: #fde49e;
$secondary: #feb941;
$accent: #6dc5d1;
$bg: #fafafa;
$bg1: #f6fafd;
$line: #e4e8eb;
$dark: #666666;
$green: #00b32a;
