@import "./variables";
@import "./mediaquery";

.election {
  .container {
    max-width: 85vw;
    width: 100%;
    margin: 1em auto;

    .button {
      padding: 6px 4em;
      border-radius: 5px;
      background-color: $primary;
      border: 1px solid $primary;
      color: white;
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      cursor: pointer;
      margin: auto;
      display: block;
    }
    .button:hover {
      background-color: transparent;
      color: $primary;
    }

    .user-list {
      height: fit-content;
      background-color: #fdffe2;
      height: fit-content;
      min-height: 20vh;
      border-radius: 8px;
      padding: 1em;
      border: 1px solid #60606010;
      box-shadow: 1px 1px 5px #60606010;
      .msg {
        display: block;
        margin: auto;
        text-align: center;
        width: 100%;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
      }

      hr {
        border: 1px solid #60606030;
        margin: 5px 0;
      }

      .bar {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        gap: 1em;

        h4 {
          font-weight: 510;
          color: $accent;
        }

        p {
          cursor: pointer;
          font-size: small;
          color: $accent;
          padding: 4px 1em;
        }
        .active {
          color: $primary;
        }
      }

      .user-box {
        padding: 10px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 1fr;
        height: fit-content;
        gap: 10px;
        cursor: pointer;
        position: relative;
        background-color: whitesmoke;

        button {
          padding: 6px 10px;
          border-radius: 5px;
          background-color: $primary;
          border: 1px solid $primary;
          color: white;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
          cursor: pointer;
        }
        button:hover {
          background-color: transparent;
          color: $primary;
        }

        img {
          max-height: 160px;
          width: 100%;
          max-width: 200px;
          height: 130px;
          object-fit: contain;
          border: 1px solid $primary;
          border-radius: 5px;
        }

        .gd {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: x-large;
        }

        .left {
          position: absolute;
          top: 10px;
          left: 10px;
          h2 {
            background-color: white;
            border-radius: 50%;
            font-size: large;
            font-weight: 600;
            height: 32px;
            width: 32px;
            display: grid;
            place-content: center;
            color: $accent;
            text-align: center;
            margin: 1px;
          }

          h4 {
            margin-top: 10px;
            font-size: small;
            font-weight: 400;
            color: $primary;
            text-align: center;
          }
        }
        .right {
          b {
            font-weight: 520;
          }
          h2 {
            font-size: medium;
            font-weight: 510;
            margin-bottom: 5px;
            color: $accent;
          }
          h3 {
            margin-top: 3px;
            font-size: small;
            font-weight: 500;
          }
          h4 {
            margin-bottom: 3px;
            font-size: small;
            font-weight: 500;
            color: $accent;
            margin-top: 8px;
          }
          p {
            font-size: small;
            margin-top: 2px;
          }
          hr {
            margin: 0 !important;
          }
        }
        .nom {
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 8px;
          color: $accent;
          font-size: x-small;
          display: grid;
          grid-template-columns: auto auto;
          gap: 10px;

          p {
            font-size: large;
            display: block;
            margin: auto;
          }

          input {
            width: 32px;
            height: 32px;
            border: 1px solid $accent;
          }
        }
      }
      .user-box:hover {
        box-shadow: 1px 1px 5px $primary;
      }
      .active {
        box-shadow: 1px 1px 5px $primary;
      }
    }
  }
}
