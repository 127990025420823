@import "./variables";
@import "./mediaquery";

.home {
  .container {
    max-width: 85vw;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1em;

    .main {
      margin: 1em 0;
      .user-list {
        height: fit-content;
        background-color: #fdffe2;
        height: fit-content;
        min-height: 20vh;
        border-radius: 8px;
        padding: 1em;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        .msg {
          display: block;
          margin: auto;
          text-align: center;
          width: 100%;
        }

        .list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }

        hr {
          border: 1px solid #60606030;
          margin: 5px 0;
        }

        .bar {
          display: grid;
          grid-template-columns: 1fr auto auto auto;
          gap: 1em;

          h4 {
            font-weight: 510;
          }

          p {
            cursor: pointer;
            font-size: small;
            color: $accent;
            padding: 4px 1em;
          }
          .active {
            color: $primary;
          }
        }

        .user-box {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          display: grid;
          grid-template-columns: auto 1fr;
          height: fit-content;
          gap: 10px;
          cursor: pointer;
          position: relative;
          background-color: whitesmoke;

          button {
            padding: 6px 10px;
            border-radius: 5px;
            background-color: $primary;
            border: 1px solid $primary;
            color: white;
            transition: background-color 0.5s ease-in-out,
              color 0.5s ease-in-out;
            cursor: pointer;
          }
          button:hover {
            background-color: transparent;
            color: $primary;
          }

          img {
            max-height: 160px;
            width: 100%;
            max-width: 200px;
            height: 130px;
            object-fit: contain;
            border: 1px solid $primary;
            border-radius: 5px;
          }

          .gd {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: x-large;
          }

          .left {
            position: absolute;
            top: 10px;
            left: 10px;
            h2 {
              background-color: white;
              border-radius: 50%;
              font-size: large;
              font-weight: 600;
              height: 32px;
              width: 32px;
              display: grid;
              place-content: center;
              color: $accent;
              text-align: center;
              margin: 1px;
            }

            h4 {
              margin-top: 10px;
              font-size: small;
              font-weight: 400;
              color: $primary;
              text-align: center;
            }
          }
          .right {
            b {
              font-weight: 520;
            }
            h2 {
              font-size: medium;
              font-weight: 510;
              margin-bottom: 5px;
              color: $accent;
            }
            h3 {
              margin-top: 3px;
              font-size: x-small;
              font-weight: 500;
            }
            h4 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 500;
              color: $accent;
              margin-top: 8px;
            }
            p {
              font-size: x-small;
              margin-top: 2px;
            }
            hr {
              margin: 0 !important;
            }
          }
          .nom {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: $secondary;
            padding: 8px;
            color: white;
            font-size: x-small;
          }
        }
        .user-box:hover {
          box-shadow: 1px 1px 5px $primary;
        }
        .active {
          box-shadow: 1px 1px 5px $primary;
        }
      }
      .ot {
        height: fit-content;
        background-color: $accent;
        height: fit-content;
        border-radius: 8px;
        padding: 1em;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        position: relative;

        .status {
          position: absolute;
          top: 1em;
          right: -1px;
          background-color: $secondary;
          padding: 8px 2em;
          color: white;
        }

        h1 {
          color: white;
          margin: 10px 0 1em 0;
          font-weight: 400;
          font-size: 44px;
        }

        button {
          background-color: $primary;
          border: 1px solid $primary;
          cursor: pointer;
          border-radius: 8px;
          color: white;
          transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
          font-size: xx-large;
          padding: 10px 1em;
        }
        button:hover {
          background-color: transparent;
          color: $primary;
        }

        b {
          font-weight: 505;
        }
        .left {
          border-radius: 5px;
          padding: 10px;
          display: grid;
          place-content: center;

          p {
            color: white;
            margin: 10px 0;
            font-size: small;
          }
          div {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            gap: 1em;
          }

          .ic {
            color: $primary;
            margin: auto;
            display: block;
            margin: auto;
          }

          h4 {
            font-size: medium;
            font-weight: 400;
            color: white;
            text-align: center;
          }
        }
      }
    }

    .sidebar {
      margin: 1em 0;

      .divauto1 {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        margin-bottom: 10px;

        h2 {
          margin: auto;
          display: block;
          text-align: left;
          width: 100%;
          color: $accent;
        }
      }

      h1 {
        font-size: large;
        color: $primary;
        text-align: center;
        margin: 8px 0 0 0;
      }

      .section {
        background-color: white;
        border: 1px solid #60606010;
        border-radius: 8px;
        padding: 1em;

        h4 {
          font-weight: 500;
        }

        h5 {
          font-weight: 500;
          font-size: smaller;
          margin: auto;
        }

        p {
          font-size: small;
        }

        hr {
          border: 1px solid #60606010;
          margin: 4px 0;
        }
      }

      .activity {
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;

        p {
          font-size: x-small;
          font-weight: 200;
        }

        h4 {
          font-weight: 400;
          font-size: small;
          margin-top: 5px;
        }
        b {
          font-weight: 505;
        }
      }
    }
  }
}
